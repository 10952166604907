<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <input type="number" class="form-control" style="width: 80px;" placeholder="시작번호" v-model="sn" />
                <input type="number" class="form-control" style="width: 80px;" placeholder="끝번호" v-model="en" />
              </div>
              <div class="col-md-4">
                <form @submit.prevent="addCase">
                  <div class="form-group">
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="API용 아이디 입력" v-model="username" />
                      <button type="submit" class="btn btn-success">추가</button>
                      <button type="button" class="btn btn-primary" @click="startCase">시작</button>
                      <button type="button" class="btn btn-danger" @click="stopCase">중지</button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-4"></div>
            </div>
            <h5 class="text-center mt-3">초당 전송 : {{ RPS }}</h5>
            <div class="row mt-5">
              <div class="col-md-6 p-2" v-for="(record, index) in caseList" :key="record.id">
                <stress-component :ref="'stress' + index" :username="record" :state="state" @updateRPS="updateRPS"></stress-component>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "@/views/_layouts/main"
import PageHeader from "@/components/page-header"
import StressComponent from '@/components/api/stress'
export default {
  components: {
    Layout,
    PageHeader,
    StressComponent
  },
  data () {
    return {
      title: 'STRESS TEST',
      caseList: [],
      username: '',
      state: 'ready',
      requestPerSecond: 0,
      RPS: 0,
      flagRPS: null,
      sn: 0,
      en: 0
    }
  },
  methods: {
    addCase: function() {
      const kgonUsername = this.username.trim()
      if (this.sn > 0 && this.en > 0) {
        for(let i = this.sn; i <= this.en; i++) {
          this.caseList.push(kgonUsername + i)
        }
      } else {
        this.caseList.push(kgonUsername)
      }

      this.username = ''
    },
    startCase: function() {
      const self = this
      this.state = 'start'
      this.flagRPS = setInterval(function() {
        self.RPS = 0
      }, 1000)
    },
    stopCase: function() {
      this.state ='stop'
      clearInterval(this.flagRPS)
    },
    updateRPS: function(count) {
      this.RPS += count
    }
  }
}
</script>
