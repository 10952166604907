<template>
  <div class="border border-right p-3">
    <h4>{{ username }} <small><span class="badge float-end" :class="{'bg-warning': state === 'ready', 'bg-primary': state === 'start', 'bg-secondary' : state === 'stop'}">{{ state.toUpperCase() }}</span></small></h4>
    <hr />
    <div class="row">
      <label class="col-md-4">CPS</label>
      <div class="col-md-8">{{ RPS }}</div>
    </div>
    <div class="row">
      <label class="col-md-4">처리결과</label>
      <div class="col-md-8">
        <span class="badge bg-success">{{ successCount.formatThousands() }}</span> | <span class="badge bg-danger">{{ failCount.formatThousands() }}</span>
      </div>
    </div>
    <div class="row">
      <label class="col-md-4">상태</label>
      <div class="col-md-8">{{ status.toUpperCase() }}</div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="list-group" style="height: 300px; overflow-y: auto;">
          <li class="list-group-item" v-for="(msg, index) in messages" :key="'msg-' + index">
            {{ JSON.stringify(msg) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import {Assert} from "@/libraries/assert";
import { Container } from 'typedi'
import axios from 'axios'
import * as numberHelper from '@/helpers/number'
import * as stringHelper from '@/helpers/string'

const mutex = Container.get('Mutex')

const URL_PRAGMATIC_BET = process.env.VUE_APP_API_PRAGMATIC_URL + '/bet.html' // 베팅
const URL_PRAGMATIC_RESULT = process.env.VUE_APP_API_PRAGMATIC_URL + '/result.html' // 결과

export default {
  props: {
    username: String,
    state: String
  },
  data () {
    return {
      packet: {
        bet: {
          amount: '200.0',
          gameId: 'vs20doghouse',
          hash: '6a69a22d74280f7f6738717a976950fb',
          providerId: 'PragmaticPlay',
          reference: '633077be2349ff3d32e81e0a',
          roundDetails: 'spin',
          roundId: '19589988831615',
          timestamp: '1664120766857',
          userId: 'top1_user1'
        },
        win: {
          amount: '120.0',
          gameId: 'vs20doghouse',
          hash: 'f8e39bb9295edb8b7ad59a58ccbac30f',
          providerId: 'PragmaticPlay',
          reference: '633077bf06522f191e4a2045',
          roundDetails: 'spin',
          roundId: '19589988831615',
          timestamp: '1664120767200',
          userId: 'top1_user1'
        }
      },
      status: 'ready', // 진행상태(ready, bet, result, fail)
      successCount: 0,
      failCount: 0,
      messages: [],
      requests: 0,
      seconds: 0,
      RPS: 0,
      flagRPS: null
    }
  },
  mounted() {

  },
  methods: {
    async send(data) {
      let mutexKey
      try {
        mutexKey = await mutex.acquire('stress-' + this.username)
        // Assert.number(1)
        // Assert.ok(!1)
        this.requests++

        let url
        if (this.status === 'bet') {
          url = URL_PRAGMATIC_BET
        } else {
          url = URL_PRAGMATIC_RESULT
        }

        const result = await axios.post(url, data)
        if (result.data.error !== 0) {
          this.messages.push(result.data)
          this.failCount++
          return false
        }

        this.successCount++

        return true
      } catch(e) {
        this.failCount++
        console.log(e)
        return false
      } finally {
        await mutex.release(mutexKey, 'stress-' + this.username)
      }
    },
    async start() {
      // 배팅과 승 데이터를 전송한다.
      while( this.state === 'start' ) {
        // bet
        this.status = 'bet'
        let data = this.packet.bet

        const roundId = stringHelper.uniqueID()
        data.reference = stringHelper.md5(stringHelper.uniqueID() + new Date().getTime().toString())
        data.roundId = roundId
        data.timestamp = new Date().getTime()
        data.userId = this.username
        data.amount = 100

        let result = await this.send(data)
        if (result !== true) {
          continue
        }

        // random
        const checkWin = numberHelper.randomRange(0, 5)
        if (checkWin === 1) {
          // win
          this.status = 'win'
          data = this.packet.win
          data.reference = stringHelper.md5(stringHelper.uniqueID() + new Date().getTime().toString())
          data.roundId = roundId
          data.timestamp = new Date().getTime()
          data.userId = this.username
          data.amount = numberHelper.randomRange(100, 1000)
          result = await this.send(data)
          if (result !== true) {
            continue
          }
        }
      }

      console.log('stopped...')
    },
    stop() {

    },
  },
  watch: {
    state: function(val) {
      if (val === 'start') {
        const self = this
        this.seconds = 0
        this.flagRPS = setInterval(function() {
          // 부모데이터로 전송
          self.seconds++
          self.RPS = parseInt(self.requests / self.seconds)
          self.$emit('updateRPS', self.RPS)
        }, 1000)

        this.start()
      } else {
        clearInterval(this.flagRPS)
      }
    }
  }
}
</script>
